@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html,
body {
  font-family: "inter", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: "inter", sans-serif;
}

.disclosure-inner-panel .py-2.align-middle.inline-block.min-w-full {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible;
  z-index: 9999;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.menu-span {
  border-top: 10px solid #374151;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -10px;
}

.menu-span-top {
  border-bottom: 10px solid #374151;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
}

.menu-span-card {
  border-bottom: 10px solid #374151;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
}

.css-qc6sy-singleValue {
  color: #6b7280 !important;
  text-align: right;
}

.css-319lph-ValueContainer {
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .css-qc6sy-singleValue {
    text-align: left;
  }
}

@media print {
  html,
  body {
    height: 100%;
  }
  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
